/* Updated root variables:
   - Replaced purple gradient with a black/black gradient.
   - Changed important text color to gold (#FFD700).
*/
html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 1)
  );
  --image-gradient: linear-gradient(
    to bottom left,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 1)
  );
  --imp-text-color: #ffd700; /* gold */
}

/* Any element that had .purple is now gold */
.purple {
  --section-background-color: linear-gradient(to bottom, #000, #000) !important;;
}

.contentStyle {
  color: white !important;
}

p {
  color: white !important;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  font-size: large;
}

.head2 {
  list-style-type: square;
}

.head3 {
  margin-left: 10px;
  list-style-type: circle;
}

.head4 {
  margin-left: 20px;
  list-style-type: square;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #000; /* black background instead of #0c0513 */
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/* Scrollbar  */
/* --------- */

/* White "stars" = white scrollbar handle, black track. */
::-webkit-scrollbar {
  width: 7px;
}

/* Track (black) */
::-webkit-scrollbar-track {
  background: #000; /* black */
}

/* Handle (white) */
::-webkit-scrollbar-thumb {
  background: #fff; /* white */
  border-radius: 12px;
}

/* Handle on hover (gold) */
::-webkit-scrollbar-thumb:hover {
  background: #ffd700; /* gold */
  border-radius: 12px;
}

/* --------- */
/* Navbar Section - MERGED STYLES */
/* --------- */

.navbar {
  position: fixed !important;
  background-color: #1b1a2ea9 !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.8rem 2rem !important;
  font-size: 1.2rem !important;
  width: 100%;
  z-index: 999;
}

.sticky {
  background-color: #1b1a2e !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3) !important;
  padding: 0.5rem 2rem !important;
  backdrop-filter: blur(15px) !important;
}

/* Brand and logo */
.navbar-brand .brand-text {
  color: #fff !important;
}

.brand-text {
  font-size: 1.5rem;
  padding-left: 0.5rem;
}

.logo {
  height: 1.4em !important;
  width: 2.5em !important;
}

/* Navbar toggler (mobile menu button) */
.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #ffd700 !important; /* Gold toggler lines */
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

/* Nav links and items */
.navbar-nav .nav-link {
  color: #fff !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.navbar-nav .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

/* Gold hover underline animation from original CSS */
.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #ffd700; /* gold highlight */
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* New modular navbar icon styles */
.nav-icon {
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

.navbar-logo {
  height: 40px;
  width: auto;
  /* Ensures the logo maintains its aspect ratio */
  object-fit: contain;
}

/* Optional: If you want to add some animation to the logo on hover */
.navbar-logo:hover {
  transform: scale(1.05);
  transition: transform 0.2s ease-in-out;
}

/* Add some spacing between logo and text */
.brand-text {
  margin-left: 10px;
  font-weight: bold;
  color: #fff;
}

/* If you need to adjust for mobile */
@media (max-width: 768px) {
  .navbar-logo {
    height: 30px; /* Slightly smaller on mobile */
  }
}



.dropdown-icon {
  margin-right: 0.2rem;
  font-size: 1rem;
}

/* Dropdown menu styles */
.dropdown-menu {
  background-color: #1b1a2e;
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.dropdown-item {
  color: #fff !important;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff !important;
}

/* Mobile responsive adjustments */
@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #000 !important;
  }

  .nav-link {
    padding: 0.7rem 1rem !important;
  }

  .navbar-nav .nav-item a::after {
    display: none !important;
  }
  
  .navbar-nav .nav-link {
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.3rem;
  }
  
  .nav-link-text {
    margin-left: 0.5rem;
  }
}

.toc {
  margin-top: 60px;
  position: fixed;
  left: menuPaddingLeft;
  top: 5em;
  padding: 1em;
  width: menuWidth;
  line-height: 2;
  color: white !important;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul ul {
  padding-left: 2em;
}

li a {
  display: inline-block;
  color: #aaa;
  text-decoration: none;
  transition: all 0.3s cubic-bezier(0.230, 1.000, 0.320, 1.000);
}

li.visible > a {
  color: #111;
  transform: translate(5px);
}

.toc-marker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.contents {
  padding: 1em;
  max-width: 800px;
  font-size: 1.2em;
  font-family: 'Frank Ruhl Libre', sans-serif;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  /* Gold toggler lines instead of purple */
  background-color: #ffd700 !important; 
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #000 !important; /* black instead of #181a27 */
  }

  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}

.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 1.4em !important;
  width: 2.5em !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

/* Gold hover underline instead of purple */
.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #ffd700; /* gold highlight */
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation;
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(14deg);
  }

  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

/* If you had a background image for #tsparticles, you can remove or set to black:
   background-color: #000 !important;
*/

.home-header {
  padding-top: 80px !important;
}

.home-section,
.home-about-section {
  position: relative;
  z-index: -1;
  /* background-image: var(--image-gradient), url(./Assets/home-bg.jpg); */
  background-position: top center;
  background-repeat: no-repeat;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.home-content {
  padding: 9rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

/* Replaced #cd5ff8 with gold for .main-name if you want it gold. */
.main-name {
  color: #ffd700;
}

/* Replaced .Typewriter__wrapper purples (#be6adf, #b562d6) with gold. */
.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #ffd700 !important;
  font-weight: 600 !important;
}

.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #ffd700 !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }

  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-description {
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* Replaced #68187a (purple) with a golden or black overlay 
     If you want a gold glow, try #ffd700. 
     If you want a subtle gold, reduce alpha, e.g. rgba(255,215,0,0.3). */
  background: rgba(255, 215, 0, 0.3);
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #ffd700; /* gold glow */
}

.home-social-icons:hover {
  color: #ffd700;
  box-shadow: 0 0 5px #ffd700;
  text-shadow: 0 0 2px #ffd700;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

/* replaced #700c86 with gold for .icon-colour if you want them gold too */
.icon-colour {
  color: #ffd700 !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: #000 !important; /* black instead of #0a0416 or #100416 */
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important;
}

.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #ccc !important; /* or #ffd700 if you want golden blockquote text */
}

/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  --section-background-color: linear-gradient(to bottom, #000, #000) !important;
}

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
  /* remove old purple box-shadow, replace with gold toned shadow or black */
  box-shadow: 0 4px 5px 3px rgba(255, 215, 0, 0.2) !important; 
}

.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(255, 215, 0, 0.3) !important; 
}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
  box-shadow: 0 3px 3px 2px rgba(255, 215, 0, 0.2) !important; 
}

.blog-link {
  color: white !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(255, 215, 0, 0.3) !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

/* Buttons:
   Use black or gold background for .btn-primary, or keep as is if you prefer. 
   Right now replaced with a gold background. */
.btn-primary {
  color: #000 !important;
  background-color: #ffd700 !important;
  border-color: #ffd700 !important;
}

.btn-primary:hover {
  color: #000 !important;
  background-color: #ffe352 !important; /* a lighter gold on hover */
  border-color: #ffe352 !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* If you want the .btn-secondary also gold:
   .btn-secondary {
     color: #000 !important;
     background-color: #ffd700 !important;
     border-color: #ffd700 !important;
   }
   .btn-secondary:hover {
     background-color: #ffe352 !important;
     border-color: #ffe352 !important;
   }
*/

.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

/* --------- */
/* About */
/* --------- */
.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  --section-background-color: linear-gradient(to bottom, #000, #000) !important;
  color: white !important;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(255, 215, 0, 0.4) !important; /* gold border */
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(255, 215, 0, 0.1) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(255, 215, 0, 0.7) !important;
}

.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}

/* --------- */
/* Resume */
/* --------- */
.resume-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  --section-background-color: linear-gradient(to bottom, #000, #000) !important;
  color: white !important;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #ffd700; /* gold line on resume */
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #333; /* dark background behind resume item titles */
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #000; /* black circle behind the gold border */
  border: 2px solid #ffd700;
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: rgba(255, 215, 0, 0.4) !important;
  border-color: rgba(255, 215, 0, 0.4) !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: rgba(255, 215, 0, 0.6) !important;
  border-color: rgba(255, 215, 0, 0.6) !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}

@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
}

.fork-btn-inner {
  line-height: 1.4em !important;
  background-color: rgba(255, 215, 0, 0.4) !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: rgba(255, 215, 0, 0.6) !important;
  border-color: rgba(255, 215, 0, 0.6) !important;
}

.fork-btn-inner::after {
  display: none !important;
}

.bg-dark {
  background-color: #000 !important;
  color: #fff !important;
}

.dir-main {
  background-color: #000 !important; /* pure black */
  color: #fff !important;           /* white text */
  min-height: 100vh;                /* optional: ensure it spans full viewport */
  /* Add any extra styling you like here */
}

.jumbotron.bg-dark,
.album.bg-dark, .floatright {
  background-color: #000000 !important; 
  color: #fff !important;
}



.navbar {
  background-color: #1b1a2ea9 !important;
  transition: all 0.3s ease-out;
  padding: 0.8rem 2rem !important;
}

.sticky {
  background-color: #1b1a2e !important;
  transition: all 0.3s ease-out;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  padding: 0.5rem 2rem !important;
}

.navbar-brand {
  color: #fff !important;
  font-weight: 600;
}

.brand-text {
  font-size: 1.5rem;
  padding-left: 0.5rem;
}

.navbar-nav .nav-link {
  color: #fff !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.navbar-nav .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-icon {
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

.dropdown-icon {
  margin-right: 0.2rem;
  font-size: 1rem;
}

.dropdown-menu {
  background-color: #1b1a2e;
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.dropdown-item {
  color: #fff !important;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff !important;
}

/* .ms-2 {
  color: black !important;
} */