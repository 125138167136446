@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(0, 0, 0), rgb(0, 0, 0));
}

/* .toc {
  nav {
    width: 220px;
    min-width: 220px;
    padding: 16px;
    align-self: flex-start;
    position: -webkit-sticky;
    position: sticky;
    top: 48px;
    overflow: auto;
    margin-top: 150px;
  }
  
  .toc ul li {
    margin-bottom: 15px;
  }
}

content{text-decoration-color: white;} */

